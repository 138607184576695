import { getConfig } from "@/constants/config"
import { store } from "@/store"
import {
  addresssSuggest,
  addToCart,
  discount,
  orderSubmit,
  setCart,
  setCartCount,
  getCart,
} from "@/store/features/cartSlice"
import { getQuantityCount, deleteFromCart } from "@/utils/cart"
import { getShortenedUrl } from "@/utils/helper"
import { FRAUD_DECLINED_MESSAGE_ENDPOINT } from "@/constants/routes"
import {
  GLOBAL_CONFIGURATION_ENDPOINT,
  GENERIC_ERROR_MESSAGE,
} from "@/constants/"
import { aemAxiosInstance } from "@/constants/api"
import {
  setPaymentFailedAction,
  setPaymentErrorMessageAction,
  setPaymentTechnicalFailedAction,
} from "@/store/features/checkoutSlice"

export const getAddressSuggestions = async addr => {
  if (!addr || addr.trim() === "") return []
  try {
    const res = await store.dispatch(addresssSuggest(addr))
    if (res && res.payload && res.payload.results) {
      return res.payload.results.map(item => item.suggestion ?? "")
    }
    return []
  } catch (err) {
    console.log("Failed to get suggestions from Experian", err)
    throw err
  }
}

export const refreshCart = async () => {
  const cart = store?.getState()?.cart?.cart
  const { lineItems = [], taxedPrice = {} } = cart

  if (lineItems?.length === 0) return
  const shippingZip = localStorage.getItem("shippingZip")

  let payload = {}
  if (shippingZip && shippingZip.trim() !== "" && !taxedPrice) {
    payload = {
      actions: [
        {
          action: "recalculate",
          updateProductData: true,
        },
        {
          action: "calculateTax",
        },
      ],
    }
  } else {
    payload = {
      actions: [
        {
          action: "recalculate",
          updateProductData: true,
        },
      ],
    }
  }
  await store
    .dispatch(addToCart(payload))
    .unwrap()
    .then(res => {
      if (res) {
        store.dispatch(setCart(res))
        const quantityLineItems = res?.lineItems
        const payload = getQuantityCount(quantityLineItems)
        store.dispatch(setCartCount(payload))
      }
    })
}

export const checkInactivePromos = async (cb, cb1, isModalOpen = false) => {
  const actions = []
  let actionData
  const cart = store?.getState()?.cart?.cart
  const { discountCodes = [] } = cart
  discountCodes
    .filter(item => item.state !== "MatchesCart")
    .map(item => {
      actions.push({
        action: "removeDiscountCode",
        discountCode: { typeId: "discount-code", id: item.discountCode?.id },
      })
      actionData = [...actions]
    })
  if (actionData?.length > 0) {
    store
      .dispatch(discount({ actions: actionData }))
      .unwrap()
      .then(res => {
        if (res && res.type === "Cart") {
          store
            .dispatch(addToCart({ actions: [{ action: "calculateTax" }] }))
            .unwrap()
            .then(resp => {
              if (resp && resp.type === "Cart") {
                store.dispatch(setCart(res))
                store.dispatch(setCartCount(res.lineItems))
                createOrder(cb, cb1, isModalOpen)
              }
            })
        }
      })
  } else {
    createOrder(cb, cb1, isModalOpen)
  }
  return actionData
}

export const createOrder = async (cb, cb1, isModalOpen = false) => {
  store.dispatch(setPaymentErrorMessageAction(false))

  const paymentId = store.getState()?.cart?.paymentId
  const {
    general: { siteName = "", orderConfirmPath = "/", homepageUrl = "/" } = {},
  } = await getConfig()

  const orderConfirmUrl = await getShortenedUrl(orderConfirmPath)
  const homePagePath = await getShortenedUrl(homepageUrl)

  const { IGLOO: { getBlackbox } = {} } = window || {}
  const { blackbox, finished } = getBlackbox?.() || {}

  const payload = {
    paymentId: paymentId,
    orderSource: siteName,
  }

  if (finished) {
    payload.deviceId = encodeURIComponent(blackbox ?? "")
  }

  store
    .dispatch(orderSubmit(payload))
    .unwrap()
    .then(res => {
      const {
        parentOrder: { orderNumber = "", customerEmail: email = "" },
      } = res
      if (res) {
        const base64Str = Buffer.from(email).toString("base64")
        const redirectionLink = `${orderConfirmUrl}?orderNumber=${orderNumber}${"&q=".concat(
          base64Str
        )}`
        cb1 && cb1(redirectionLink, isModalOpen)
        window.history.replaceState(null, null, homePagePath)
        window.location.href = redirectionLink
      } else {
        cb && cb(false) // enable Place Order CTA on failure
      }
      store.dispatch(setPaymentFailedAction(false))
    })
    .catch(error => {
      const { checkout: { isSetCardDetails = "" } = {} } = store.getState()
      const { error: errorMsg, errorCode, paymentCompleted = false } = error
      if (paymentCompleted) {
        store.dispatch(setPaymentTechnicalFailedAction(true))
        // popup condition
      } else if (errorMsg === "Declined") {
        deleteFromCart()
        window.location.href = FRAUD_DECLINED_MESSAGE_ENDPOINT
      } else {
        store
          .dispatch(getCart())
          .unwrap()
          .then(async () => {
            store.dispatch(setPaymentFailedAction(true))
            await refreshCart()
            const { generic: { aciPaymentErrorMessagesPath = "" } = {} } =
              store.getState()
            try {
              const { data } = await aemAxiosInstance.get(
                GLOBAL_CONFIGURATION_ENDPOINT +
                  "?path=" +
                  aciPaymentErrorMessagesPath
              )
              let { value: errorMsg } = data.find(e => e.text === errorCode)

              if (
                errorMsg &&
                typeof errorMsg === "string" &&
                errorMsg?.includes("card-last-4-digits") &&
                isSetCardDetails
              ) {
                errorMsg = errorMsg.replace(
                  "card-last-4-digits",
                  isSetCardDetails
                )
              }
              // payment  section open error condition
              store.dispatch(
                setPaymentErrorMessageAction(
                  errorMsg ? errorMsg : GENERIC_ERROR_MESSAGE
                )
              )
            } catch {
              // payment  section open error condition
              if (
                errorMsg &&
                typeof errorMsg === "string" &&
                errorMsg?.includes("card-last-4-digits") &&
                isSetCardDetails
              ) {
                errorMsg = errorMsg.replace(
                  "card-last-4-digits",
                  isSetCardDetails
                )
              }
              store.dispatch(
                setPaymentErrorMessageAction(
                  errorMsg ? errorMsg : GENERIC_ERROR_MESSAGE
                )
              )
            }
          })
        cb && cb(false)
      }
    })
}

export const getEventInfo = (type, link, needJson) => {
  const eventInfo = {
    eventAction: "checkout:review:" + type,
    eventName: "checkout:review:" + type,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: type,
    internalLinkPosition: "checkout",
    internalLinkType: "checkout:navigation",
    internalLinkZoneName: "checkout:review",
    internalLinkURL: link
      ? (link.indexOf("http") === -1 ? window.location.origin : "") + link
      : "n/a",
    clickInternalLinks: "true",
  }
  if (needJson) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

export const getShippingAddressFormated = data => {
  const {
    city = "",
    country = "",
    email = "",
    firstName = "",
    lastName = "",
    phone = "",
    postalCode = "",
    state = "",
    streetName = "",
    streetNumber = "",
    id = "",
  } = data

  return {
    fname: {
      value: firstName,
      error: false,
      message: "",
    },
    lname: {
      value: lastName,
      error: false,
      message: "",
    },
    email: {
      value: email,
      error: false,
      message: "",
    },
    phone: {
      value: phone,
      error: false,
      message: "",
    },
    address1: {
      value: streetName,
      error: false,
      message: "",
    },
    address2: streetNumber,
    city: {
      value: city,
      error: false,
      message: "",
    },
    state: {
      value: state,
      error: false,
      message: "",
    },
    zipcode: {
      value: postalCode,
      error: false,
      message: "",
    },
    country: {
      value: country,
      error: false,
      message: "",
    },
    notes: "",
    label: {
      show: true,
      value: "",
    },
    id: id,
  }
}
